<template>

<app-content :loading="is.loading && !is.initialised">

	<app-content-head title="Managed Badges" subtitle="See and manage the additional badges you've purchased for other people." />

	<app-content-body :loading="is.loading && is.initialised" :is-empty="!collection.length" :is-grid="true" placeholder="Sorry, no badges found.">

		<com-badge v-for="item in collection" :key="item.id" :item="item" v-on:saved="loadRefresh" />

	</app-content-body>

</app-content>

</template>

<script>

import mixCollection from '@/mixin/collection'
import comBadge from './conventionregistrationmanaged/Badge'

export default {

	mixins: [mixCollection],

	components: {
		comBadge
	},

	data: function() {

		return {
			endpoint: 'convention/registration/managed'
		}

	}

}

</script>

<style scoped>

</style>